<template>
    <div>
        <h4 class="bold text-uppercase">Thông báo</h4>
        <div class="row">
            <div class="col-md-4 col-12 mb-3" v-for="(log, index) in notifies" :key="index">
                <div class="card card-white">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-7">
                                <h5 class="bold mb-0 block-text green">{{ log.actions_html }}</h5>
                            </div>
                            <div class="col text-right">
                                <h6 class="font-14">{{ log.created_at | timeAgo }}</h6>
                            </div>
                        </div>
                        <h6 class="font-14 mb-0 block-text-2" :title="log.content">{{ log.content }}</h6>
                        <h6 class="mb-0 bold green">
                            <span v-show="log.quantity"
                                ><i class="fab fa-telegram-plane mr-1"></i> {{ log.quantity | formatNumber }}</span
                            >
                            {{ log.tool ? log.tool.name : log.action }}
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { userGetNotify } from "../../api/user"
export default {
    name: "client-manager",
    data() {
        return {
            panel: "",
            user_update: {},
            notifies: []
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        site() {
            return this.$store.state.site.site
        },
        reload() {
            return this.$store.state.app.reload
        }
    },
    created() {
        this.getNotify()
    },
    methods: {
        getNotify: async function() {
            let data = await userGetNotify()
            if (data.status === 200 && data.success) {
                this.notifies = data.data.data.map(item => {
                    // item.html = item.status === 1 ? "<span class='badge badge-pill badge-success'>Thành công</span>" : "<span class='badge badge-pill badge-danger'>Thất bại</span>";
                    // item.class = item.status === 1 ? "btn btn-success btn-circle" : "btn btn-danger btn-circle";
                    // item.message = item.content;
                    item.actions_html = item.actions ? item.actions.name : item.action
                    item.name = this.user ? this.user.name : ""
                    return item
                })
            }
        }
    }
}
</script>
